import React from "react";

const About = () => {
  return (
    <div className="mt-4 w-full">
      <br />
      <div className="m-4 rounded-lg border border-gray-300 bg-white p-6 shadow-md">
        <p>
          <h2 className="mb-4 justify-center text-xl font-bold">
            Programme prévisionnel
          </h2>
          <p>
            <b>Date</b> : Jeudi 29 mai 2025 (Ascension) de 10h à 18h
          </p>
          <br />
          <p>
            <b>Lieu</b> : Chalet de l'Ermitage, 23 rue de l'Ermitage, 78000
            Versailles
          </p>
          <p className="mt-1 text-sm">
            <a
              className="text-blue-700 underline"
              href="https://www.ermitage-fondacio.fr/index.html#acces"
              target="_blank"
            >
              Accès en transports en commun et voiture
            </a>

            <br />
          </p>
          <br />
          <p>
            <b>Matin</b>
          </p>
          <ul className="ml-5 list-disc">
            <li>
              À partir de 10h, nous vous accueillons au Chalet de l'Ermitage
            </li>
            <li>Remise de votre badge nominatif</li>
            <li>
              Des arbres généalogiques seront affichés pour vous aider à trouver
              votre filiation
            </li>
            <li>
              Pensez à apporter des photos, souvenirs, histoires de famille à
              partager
            </li>
            <li>
              Si le beau temps le permet, nous pourrons profiter du parc autour
              du chalet !
            </li>
          </ul>
          <br />
          <p>
            <b>Activités optionnelles</b>
          </p>
          <ul className="ml-5 list-disc">
            <li>
              Messe en la cathédrale Notre-Dame de Versailles (horaire à
              confirmer)
            </li>
            <li>
              Promenade jusqu'à la tombe Saxcé au Cimetière Saint-Louis à
              Versailles
            </li>
          </ul>
          <br />
          <p>
            <b>12h30</b> : Déjeuner-buffet au Chalet de l'Ermitage
            <br />
            Discours de Charles de Saxcé
          </p>
          <br />
          <p>
            <b>Après-midi</b>
          </p>
          <ul className="ml-5 list-disc">
            <li>14h30 : Photographie officielle</li>
            <li>Divers jeux pour les petits et les grands</li>
            <li>18h : Fin de la cousinade</li>
          </ul>
          <br />
          <p className="text-justify">
            Pour toute information complémentaire, n'hésitez pas à nous
            contacter à l'adresse <br />
            <a
              className="text-blue-700 underline"
              href="mailto:cousinade@desaxce.com"
            >
              cousinade@desaxce.com
            </a>
          </p>
        </p>
      </div>
    </div>
  );
};

export default About;
