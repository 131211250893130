import {
  NavLink,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import React, { useEffect } from "react";

import About from "./About";
import Participants from "./Participants";
import Poll from "./Poll";

const MainApp = () => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return (
    <Router className="flex min-h-screen flex-col">
      <div className="fixed left-0 right-0 top-0 z-10 flex flex-col items-center bg-regal-blue p-2">
        <nav className="w-full max-w-2xl pl-4 pr-4">
          <ul className="m-0 flex list-none justify-center gap-2 p-0">
            <li className="flex-1">
              <NavLink
                className={({ isActive }) =>
                  `block rounded-lg bg-regal-or px-4 py-2 text-center font-bold text-regal-blue-light no-underline hover:bg-regal-or-light ${
                    isActive
                      ? "bg-regal-or-light outline outline-2 outline-gray-300"
                      : "text-regal-blue"
                  }`
                }
                to="/a-propos"
              >
                Informations
              </NavLink>
            </li>
            <li className="flex-1">
              <NavLink
                className={({ isActive }) =>
                  `block rounded-lg bg-regal-or px-4 py-2 text-center font-bold text-regal-blue-light no-underline hover:bg-regal-or-light ${
                    isActive
                      ? "bg-regal-or-light outline outline-2 outline-gray-300"
                      : "text-regal-blue"
                  }`
                }
                to="/"
              >
                Inscriptions
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="flex h-screen w-full flex-row pt-6"
        // style={{ paddingTop: "90px" }}
      >
        <div className="w-4/4 h-full flex-grow overflow-auto">
          <div className="app-container h-full">
            <div className="flex flex-grow items-center justify-center">
              <Routes>
                <Route
                  path="/"
                  element={
                    <div className="w-full max-w-max overflow-auto">
                      <Poll />
                    </div>
                  }
                />
                <Route
                  path="/a-propos"
                  element={
                    <div className="w-full max-w-max overflow-auto">
                      <About />
                    </div>
                  }
                />
                <Route
                  path="/participants"
                  element={
                    <div className="w-full max-w-lg overflow-auto">
                      <Participants />
                    </div>
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default MainApp;
