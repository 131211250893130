import { MailIcon } from "lucide-react";
import React, { useEffect, useState } from "react";

const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

const EventRegistration = () => {
  const [submitted, setSubmitted] = useState(false);
  const [totalAmount, setTotalAmount] = useState(30);
  const [formData, setFormData] = useState({
    familyName: "",
    firstName: "",
    email: "",
    phone: "",
    adults: 1,
    accompaniedYouth: 0,
    precisions: "",
    comments: "",
  });

  useEffect(() => {
    const adultTotal = (formData.adults ? Number(formData.adults) : 0) * 30;
    const youthTotal =
      (formData.accompaniedYouth ? Number(formData.accompaniedYouth) : 0) * 15;
    setTotalAmount(adultTotal + youthTotal);
  }, [formData.adults, formData.accompaniedYouth]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "number" ? value : value,
    });
  };

  const handleInvalidEmail = (e) => {
    e.target.setCustomValidity("Veuillez renseigner une adresse email valide.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://cousinade.hugues-de-saxce.workers.dev/store",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("SUCCESS!", response.status);
        setSubmitted(true);
      } else {
        console.error("FAILED...", response.status);
      }
    } catch (err) {
      console.error("ERROR:", err);
    }
  };

  const handleInput = (e) => {
    e.target.setCustomValidity("");
  };

  if (submitted) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="w-full max-w-3xl rounded-lg border border-gray-300 p-6 shadow-md">
          <div className="py-12 text-center">
            <h2 className="mb-4 text-2xl font-semibold text-green-600">
              Merci pour votre inscription!
            </h2>
            <p className="">
              Nous vous donnons rendez-vous au 29 mai. Si d'ici là vous avez des
              questions, n'hésitez pas à nous contacter{" "}
              <a className="font-mono" href="mailto:cousinade@desaxce.com">
                cousinade@desaxce.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ml-4 mr-4 mt-10 flex flex-col items-center justify-center">
      {/* Fixed height container with scrollable content */}
      <div className="mb-4 mt-4 w-full max-w-3xl rounded-lg border border-gray-300 bg-white shadow-md">
        {/* Scrollable form content */}
        <div className="p-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="space-y-2">
                <label className="block font-medium text-gray-700">
                  * Nom :
                  <input
                    autoFocus
                    className="mt-1 w-full rounded-md border-2 border-gray-300 p-2 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    type="text"
                    name="familyName"
                    value={formData.familyName}
                    onChange={handleChange}
                    onInput={handleInput}
                    autoComplete="off"
                    required
                  />
                </label>
              </div>

              <div className="space-y-2">
                <label className="block font-medium text-gray-700">
                  * Prénom :
                  <input
                    className="mt-1 w-full rounded-md border-2 border-gray-300 p-2 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    onInput={handleInput}
                    autoComplete="off"
                    required
                  />
                </label>
              </div>
            </div>

            <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="space-y-2">
                <label className="block font-medium text-gray-700">
                  * Email :
                  <input
                    className="mt-1 w-full rounded-md border-2 border-gray-300 p-2 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    type="email"
                    name="email"
                    placeholder="Adresse email"
                    value={formData.email}
                    onChange={handleChange}
                    onInvalid={handleInvalidEmail}
                    onInput={handleInput}
                    autoComplete="off"
                    required
                  />
                </label>
              </div>

              <div className="space-y-2">
                <label className="block font-medium text-gray-700">
                  Téléphone :
                  <input
                    className="mt-1 w-full rounded-md border-2 border-gray-300 p-2 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    type="tel"
                    name="phone"
                    placeholder="Numéro de téléphone"
                    value={formData.phone}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>

            <div className="mb-4">
              <h3 className="mb-4 text-lg font-medium text-gray-800">
                Nombre de participants total :
              </h3>
              <div className="grid grid-cols-1 gap-6 rounded-md bg-gray-50 p-4 md:grid-cols-2">
                <div className="flex items-center space-x-4">
                  <div className="group relative flex h-8 w-8 items-center justify-center rounded-full bg-blue-100 font-bold text-blue-600">
                    <span className="pointer-events-none">A</span>
                    <span className="pointer-events-none invisible absolute -top-8 left-1/2 -translate-x-1/2 transform whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity duration-200 group-hover:visible group-hover:opacity-100">
                      Adultes
                    </span>
                  </div>
                  <label className="flex flex-1 items-center">
                    <span className="w-full md:w-2/3">15 ans et plus :</span>
                    <input
                      className="ml-2 w-20 rounded-md border-2 border-gray-300 p-2 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      type="number"
                      name="adults"
                      value={formData.adults}
                      onChange={handleChange}
                      min={0}
                      max={20}
                      autoComplete="off"
                    />
                  </label>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="group relative flex h-8 w-8 items-center justify-center rounded-full bg-green-100 font-bold text-green-600">
                    <span className="pointer-events-none">J</span>
                    <span className="pointer-events-none invisible absolute -top-8 left-1/2 -translate-x-1/2 transform whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity duration-200 group-hover:visible group-hover:opacity-100">
                      Jeunes
                    </span>
                  </div>
                  <label className="flex flex-1 items-center">
                    <span className="w-full md:w-2/3">8-14 ans :</span>
                    <input
                      className="ml-2 w-20 rounded-md border-2 border-gray-300 p-2 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      type="number"
                      name="accompaniedYouth"
                      value={formData.accompaniedYouth}
                      onChange={handleChange}
                      min="0"
                      max="20"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-6">
              <label className="mb-2 block font-medium text-gray-700">
                Noms et prénoms :
                <textarea
                  className="mt-1 min-h-32 w-full rounded-md border-2 border-gray-300 p-3 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  name="precisions"
                  placeholder={`Merci de préciser les noms et prénoms des personnes inscrites, moins de 8 ans inclus.`}
                  value={formData.precisions}
                  onChange={handleChange}
                ></textarea>
              </label>
            </div>
            <div className="mb-8 rounded-lg border border-blue-100 bg-blue-50 p-6">
              <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
                <h3 className="text-lg font-medium text-gray-800">
                  Participation aux frais
                </h3>
                <div className="mt-2 text-2xl font-bold text-blue-600 md:mt-0">
                  {totalAmount} €
                </div>
              </div>

              <p className="mb-4 text-sm text-gray-600">
                Ce montant comprend le déjeuner-buffet (entrée + plats +
                boissons non alcoolisées)
                <br />
                Pour les <b>desserts</b>, nous faisons confiance à vos talents.
                Produits faits maison bienvenus !<br />
                Pour les <b>boissons alcoolisées</b>, faites nous goûter les
                vins de votre région !
              </p>
              <div className="border-t border-blue-200 pb-7 pt-4">
                <p className="mb-2 font-medium text-gray-700">Tarifs :</p>
                <ul className="grid grid-cols-1 gap-2 md:grid-cols-3">
                  <li className="rounded border border-blue-200 bg-white p-2 text-center">
                    <span className="block text-lg font-bold">Gratuit</span>
                    <span className="text-sm text-gray-600">
                      Moins de 8 ans
                    </span>
                  </li>
                  <li className="rounded border border-blue-200 bg-white p-2 text-center">
                    <span className="block text-lg font-bold">15€</span>
                    <span className="text-sm text-gray-600">De 8 à 14 ans</span>
                  </li>
                  <li className="rounded border border-blue-200 bg-white p-2 text-center">
                    <span className="block text-lg font-bold">30€</span>
                    <span className="text-sm text-gray-600">
                      15 ans et plus
                    </span>
                  </li>
                </ul>
              </div>

              <div className="border-t border-blue-200 pt-4">
                <p className="mb-2 font-medium text-gray-700">
                  Veuillez adresser votre paiement au choix par :
                </p>
                <ul className="list-disc space-y-1 pl-5 text-gray-600">
                  <li>
                    Chèque à l'ordre de <b>Hugues de Saxcé</b>{" "}
                    <div className="flex flex-row items-center gap-2">
                      <MailIcon className="h-4" /> 29 Rue Bleue, 75009 PARIS
                    </div>
                  </li>
                  <li>
                    Virement à l'IBAN *:
                    <div className="my-2 flex justify-center">
                      <div className="rounded-md border-2 border-gray-300 bg-gray-100 px-4 py-1">
                        <span className="font-mono">
                          FR19 2004 1000 0154 9364 9N02 041
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
                <p className="text-sm text-gray-500">
                  * Merci de préciser nom et prénom dans le libellé du virement
                </p>
              </div>
            </div>

            <div className="mb-6">
              <label className="mb-2 block font-medium text-gray-700">
                Commentaires / Suggestions :
                <textarea
                  className="mt-1 min-h-32 w-full rounded-md border-2 border-gray-300 p-3 transition-colors focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                ></textarea>
              </label>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-blue-600 px-6 py-3 font-medium text-white transition-colors hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventRegistration;
