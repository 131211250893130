import Skeleton from "react-loading-skeleton";
import React, { useEffect, useState } from "react";

const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

const Participants = () => {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalParticipants, setTotalParticipants] = useState(0);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        console.log("Starting query");
        const response = await fetch(
          "https://cousinade.hugues-de-saxce.workers.dev/entries",
          {
            headers: {
              Authorization: `Bearer ${AUTH_TOKEN}`,
            },
          }
        );
        console.log("Ended query");
        if (!response.ok) {
          throw new Error("Failed to fetch participants");
        }

        const data = await response.json();
        // Filter participants who agreed to share publicly
        const publicParticipants = data.entries;

        setTotalParticipants(data.totalParticipants);
        setParticipants(publicParticipants);
        setLoading(false);
      } catch (err) {
        setError("An error occurred while fetching participants");
        setLoading(false);
      }
    };

    fetchParticipants();
  }, []);

  if (loading) {
    return (
      <div className="m-auto w-full">
        <Skeleton />
      </div>
    );
  }

  if (error) {
    return <div className="m-auto w-full">Erreur: {error}</div>;
  }

  return (
    <div className="m-auto w-full">
      <br />
      <div className="m-4">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 p-2">Nom</th>
              <th className="border border-gray-300 p-2"># Personnes</th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
              >
                <td className="border border-gray-300 p-2">
                  {participant.name}
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  {participant.participants}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="m-4 mt-4">
        <p>
          Participants affichés :{" "}
          {participants.reduce(
            (acc, participant) => acc + parseInt(participant.participants),
            0
          )}
        </p>
        <p>Total participants : {totalParticipants}</p>
      </div>
    </div>
  );
};

export default Participants;
